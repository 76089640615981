<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{$t('elearning_iabm.approve_circular_publication')}}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.fiscal_year')}}
                    </template>
                    <v-select name="organization"
                      v-model="search.fiscal_year_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= fiscalYearList
                      :placeholder="$t('globalTrans.select')"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('elearning_iabm.nominated_organization_type')"
                      label-for="organization"
                  >
                    <v-select name="organization"
                      v-model="search.org_type_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= orgTypeList
                      :placeholder="$t('globalTrans.select')"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('elearning_iabm.nominated_organization')"
                      label-for="organization"
                  >
                    <v-select name="organization"
                      v-model="search.org_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= orgList
                      :placeholder="$t('globalTrans.select')"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="12" sm="12" class="text-right">
                  <b-button size="sm" variant="primary" @click="searchData">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
            </b-row>
        </template>
      </card>
      <body-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_iabm.approve_circular_publication')}} {{$t('globalTrans.list')}}</h4>
        </template>
        <template v-slot:body>
            <b-overlay :show="loadingState">
              <select-column-check :labelData="labelData" :search="search" :columns="columns" />
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                    <template v-slot:cell(index)="data">
                    {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_type_id)="data">
                      {{ getOrgType(data.item.org_type_id) }}
                    </template>
                    <template v-slot:cell(training_start_date)="data">
                      <span class="capitalize">{{ data.item.training_start_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(training_end_date)="data">
                      <span class="capitalize">{{ data.item.training_end_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                    <span class="badge badge-primary" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                    <span class="badge badge-secondary" v-else-if="data.item.status == 2">{{$t('globalTrans.forward')}}</span>
                    <span class="badge badge-success" v-else-if="data.item.status == 3">{{$t('globalTrans.approved')}}</span>
                    <span class="badge badge-danger" v-else-if="data.item.status == 4">{{$t('globalTrans.return')}}</span>
                    <span class="badge badge-success" v-else-if="data.item.status == 5">{{$t('globalTrans.published')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-5 variant="iq-bg-success mr-1" size="sm" @click="detailData(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>
                      <b-button v-if="data.item.status > 1" v-b-modal.modal-form-forward-details variant="iq-bg-info mr-1" size="sm" @click="forwardDetails(data.item)" class="action-btn active" :title="$t('exportTrophyGazette.forward_comment')"><i class="ri-message-2-line"></i></b-button>
                    </template>
                  </b-table>
                  <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                    <b-pagination
                      v-model="pagination.currentPage"
                      :perPage="search.limit"
                      :total-rows="pagination.totalRows"
                      @input="searchData"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
        </template>
      </body-card>
        <b-modal id="modal-5" size="lg" hide-footer :title="$t('elearning_iabm.circular_info')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <!-- <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
            </b-button> -->
            <Details :item="item" :key="id" ref="details"/>
        </b-modal>
        <b-modal id="modal-form-forward-details" size="lg" :title="$t('exportTrophyGazette.forward_comment')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <ForwardDetails :item="itemData"/>
        </b-modal>
      </div>
</template>
<script>

import ForwardDetails from './ForwardComments.vue'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularPublicationApproveList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'
// import ExportPdf from './export_pdf_details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Details, ForwardDetails
    },
    data () {
      return {
        itemData: [],
        orgList: [],
        itemId: '',
        status: '',
        search: {
          org_type_id: null,
          org_id: null,
          fiscal_year_id: null,
          limit: 20
        },
        id: 0,
        item: '',
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
          { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
          { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.nominated_organization_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.training_start_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.training_end_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } }
        ]
      }
    },
    computed: {
      orgTypeList: function () {
        return [
          {
            value: 1,
            text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
            text_en: 'MOC',
            text_bn: 'এমওসি'
          },
          {
            value: 2,
            text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
            text_en: 'Private',
            text_bn: 'ব্যক্তিগত'
          }
        ]
      },
      currentFiscalYearId: function () {
        return this.$store.state.TrainingElearning.currentFiscalYearId
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_iabm.approve_circular_publication') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.approve_circular_publication') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
            return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'index' },
            { key: 'circular_memo_no' },
            { key: 'fiscal_year_bn' },
            { key: 'org_type_id' },
            { key: 'training_title_bn' },
            { key: 'training_start_date' },
            { key: 'training_end_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'index' },
            { key: 'circular_memo_no' },
            { key: 'fiscal_year' },
            { key: 'org_type_id' },
            { key: 'training_title' },
            { key: 'training_start_date' },
            { key: 'training_end_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        // org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.currentFiscalYearId
      })
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      },
      'search.org_type_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.orgList = this.getOrgData(newVal)
        }
      }
    },
    methods: {
      forward (item) {
        this.itemData = item
      },
      forwardDetails (item) {
        this.itemData = item
      },
      approveReturn (id, status) {
        this.itemId = id
        this.status = status
      },
      detailData (item) {
          this.item = item
      },
      dataChange () {
        this.loadData()
      },
      details (item) {
          this.item = item
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationApproveList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }
          const officeTypeObj = this.$store.state.CommonService.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
          const officeTypeData = {}
          if (typeof officeTypeObj !== 'undefined') {
            officeTypeData.office_type = officeTypeObj.text_en
            officeTypeData.office_type_bn = officeTypeObj.text_bn
          } else {
            officeTypeData.office_type = ''
            officeTypeData.office_type_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          //   fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }

          return Object.assign({}, item, orgData, officeData, officeTypeData, fiscalYearData, trainingTitleData, trainingTypeData, trainingCategoryData)
        })
        return listData
      },
      getOrgType (orgIds) {
        if (orgIds !== null) {
          const arr = []
          orgIds.forEach(item => {
          const orgId = this.orgTypeList.find(obj => obj.value === item)
            if (orgId !== undefined) {
              const orgName = this.$i18n.locale === 'en' ? ' ' + orgId.text_en : ' ' + orgId.text_bn
              arr.push(orgName)
            }
          })
          return arr.toString()
        }
      },
      getOrgData (orgId) {
        return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === orgId)
      }
    }
}
</script>
